var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory2", attrs: { id: "home_container" } },
    [
      _c(
        "div",
        { attrs: { id: "pvhinfo_container" } },
        [
          _c(
            "el-container",
            [
              _c(
                "el-header",
                {
                  staticClass: "headerDiv",
                  staticStyle: { padding: "0", "background-color": "#E6E6E6" }
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { width: "100%", "margin-top": "10px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 8, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("Brand")) + "：")]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t("Brand")
                              },
                              on: { change: _vm.brandselectChanged },
                              model: {
                                value: _vm.selBrandVal,
                                callback: function($$v) {
                                  _vm.selBrandVal = $$v
                                },
                                expression: "selBrandVal"
                              }
                            },
                            _vm._l(_vm.brandData, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 8, md: 58, lg: 4, xl: 4 } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("Region")) + "：")]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t("Region")
                              },
                              on: { change: _vm.addressSelectChanged },
                              model: {
                                value: _vm.selAddressVal,
                                callback: function($$v) {
                                  _vm.selAddressVal = $$v
                                },
                                expression: "selAddressVal"
                              }
                            },
                            _vm._l(_vm.addressData, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 8, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                              " +
                                _vm._s(_vm.$t("Level")) +
                                "："
                            )
                          ]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t("Classification")
                              },
                              on: { change: _vm.levelSelectChanged },
                              model: {
                                value: _vm.selLevelVal,
                                callback: function($$v) {
                                  _vm.selLevelVal = $$v
                                },
                                expression: "selLevelVal"
                              }
                            },
                            _vm._l(_vm.levelData, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-top": "10px" },
                          attrs: { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              on: { change: _vm.changeRadioEvent },
                              model: {
                                value: _vm.radio,
                                callback: function($$v) {
                                  _vm.radio = $$v
                                },
                                expression: "radio"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("hour")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-top": "10px" },
                          attrs: { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              on: { change: _vm.changeRadioEvent },
                              model: {
                                value: _vm.radio,
                                callback: function($$v) {
                                  _vm.radio = $$v
                                },
                                expression: "radio"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("week")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-top": "10px" },
                          attrs: { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "3" },
                              on: { change: _vm.changeRadioEvent },
                              model: {
                                value: _vm.radio,
                                callback: function($$v) {
                                  _vm.radio = $$v
                                },
                                expression: "radio"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("month")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-top": "10px" },
                          attrs: { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "4" },
                              on: { change: _vm.changeRadioEvent },
                              model: {
                                value: _vm.radio,
                                callback: function($$v) {
                                  _vm.radio = $$v
                                },
                                expression: "radio"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("year")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 8, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("Brand")) + "：")]),
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.paramBrand,
                              callback: function($$v) {
                                _vm.paramBrand = $$v
                              },
                              expression: "paramBrand"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 8, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("Region")) + "：")]),
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.paramAddress,
                              callback: function($$v) {
                                _vm.paramAddress = $$v
                              },
                              expression: "paramAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 8, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("Level")) + "：")]),
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.paramLevel,
                              callback: function($$v) {
                                _vm.paramLevel = $$v
                              },
                              expression: "paramLevel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-top": "10px" },
                          attrs: { xs: 6, sm: 5, md: 5, lg: 3, xl: 3 }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "5" },
                              model: {
                                value: _vm.radio,
                                callback: function($$v) {
                                  _vm.radio = $$v
                                },
                                expression: "radio"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("zdyTime")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 9, sm: 8, md: 8, lg: 3, xl: 3 } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "95%" },
                            attrs: {
                              type: "date",
                              placeholder: _vm.$t("startDate")
                            },
                            on: { change: _vm.changeStartTime },
                            model: {
                              value: _vm.startDate,
                              callback: function($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 9, sm: 8, md: 8, lg: 3, xl: 3 } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "95%" },
                            attrs: {
                              type: "date",
                              placeholder: _vm.$t("endDate")
                            },
                            on: { change: _vm.changeEndTime },
                            model: {
                              value: _vm.endDate,
                              callback: function($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 3, md: 3, lg: 3, xl: 3 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "big" },
                              on: { click: _vm.searchFrom }
                            },
                            [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { width: "100%", "margin-top": "15px" } },
                    [
                      _c("el-col", {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 1 }
                      }),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 5 }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "background-color": "#15A2FF",
                                display: "flex",
                                "flex-direction": "column",
                                "margin-right": "1rem",
                                "border-radius": "5px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "500",
                                    color: "#fff",
                                    "font-size": "14px",
                                    "margin-left": "5px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("ordersVar")))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "700",
                                        color: "#fff",
                                        "font-size": "18px",
                                        "margin-right": "5px",
                                        "margin-left": "5px"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.topInfoData.ordersVar))]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c("el-col", {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 3 }
                      }),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 5 }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "background-color": "#15A2FF",
                                display: "flex",
                                "flex-direction": "column",
                                "margin-right": "1rem",
                                "border-radius": "5px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "500",
                                    color: "#fff",
                                    "font-size": "14px",
                                    "margin-left": "5px",
                                    "padding-bottom": "3px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("numOrdCom")))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "700",
                                        color: "#fff",
                                        "font-size": "18px",
                                        "margin-right": "5px",
                                        "margin-left": "5px"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.topInfoData.numOrdCom))]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c("el-col", {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 3 }
                      }),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 5 }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "background-color": "#15A2FF",
                                display: "flex",
                                "flex-direction": "column",
                                "margin-right": "1rem",
                                "border-radius": "5px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "500",
                                    color: "#fff",
                                    "font-size": "14px",
                                    "margin-left": "5px",
                                    "padding-bottom": "3px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("numOrdNoCom")))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "700",
                                        color: "#fff",
                                        "font-size": "18px",
                                        "margin-right": "5px",
                                        "margin-left": "5px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.topInfoData.numOrdNoCom)
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c("el-col", {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 1 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-container",
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        width: "100%",
                        "background-color": "#E6E6E6"
                      }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-right": "20px" },
                          attrs: { span: 12 }
                        },
                        [
                          _c(
                            "el-row",
                            { staticStyle: { width: "100%" } },
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "chartsDiv" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chartsHeaderDiv",
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "titImg",
                                            attrs: {
                                              src: "/img/button/angleMark.png",
                                              alt: ""
                                            }
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "tit",
                                              staticStyle: {
                                                "text-overflow": "ellipsis",
                                                overflow: "hidden",
                                                "white-space": "nowrap",
                                                display: "inline-block",
                                                "vertical-align": "middle"
                                              },
                                              attrs: {
                                                title: _vm.$t(
                                                  "SUPPLIER CERTIFICATION QUANTITY"
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("PieTitleA"))
                                              )
                                            ]
                                          ),
                                          _c("el-button", {
                                            staticClass: "btnDetail",
                                            attrs: {
                                              icon: "el-icon-s-order",
                                              type: "text",
                                              title: _vm.$t("Check the details")
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.jupmSupplierCer()
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "chartsBodyDiv" }, [
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loading01,
                                          expression: "loading01"
                                        }
                                      ],
                                      staticStyle: {
                                        width: "100%",
                                        height: "208px",
                                        padding: "10px 0 5px 0"
                                      },
                                      attrs: { id: "charts_01" }
                                    })
                                  ])
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-right": "20px" },
                          attrs: { span: 12 }
                        },
                        [
                          _c("div", { staticClass: "chartsDiv" }, [
                            _c(
                              "div",
                              {
                                staticClass: "chartsHeaderDiv",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _c("img", {
                                      staticClass: "titImg",
                                      attrs: {
                                        src: "/img/button/angleMark.png",
                                        alt: ""
                                      }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "tit",
                                        staticStyle: {
                                          "text-overflow": "ellipsis",
                                          overflow: "hidden",
                                          "white-space": "nowrap",
                                          display: "inline-block",
                                          "vertical-align": "middle"
                                        },
                                        attrs: {
                                          title: _vm.$t("TOTAL BRAND ANALYSIS")
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("OrderNums")))]
                                    ),
                                    _c("el-button", {
                                      staticClass: "btnDetail",
                                      attrs: {
                                        icon: "el-icon-s-order",
                                        type: "text",
                                        title: _vm.$t("Check the details")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.jupmPoDetail()
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("div", { staticClass: "chartsBodyDiv" }, [
                              _c("div", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading02,
                                    expression: "loading02"
                                  }
                                ],
                                staticStyle: {
                                  width: "100%",
                                  height: "208px",
                                  padding: "10px 0 5px 0"
                                },
                                attrs: { id: "charts_02" }
                              })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("el-row")
                ],
                1
              ),
              [
                _c(
                  "avue-crud",
                  {
                    ref: "crud",
                    attrs: {
                      option: _vm.setData.tableOpt,
                      data: _vm.tableData,
                      "row-style": _vm.rowStyle,
                      page: _vm.page,
                      "summary-method": _vm.summaryMethod
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.page = $event
                      },
                      "current-change": _vm.currentChange,
                      "sort-change": _vm.sortChange
                    },
                    model: {
                      value: _vm.obj,
                      callback: function($$v) {
                        _vm.obj = $$v
                      },
                      expression: "obj"
                    }
                  },
                  [
                    _c(
                      "template",
                      {
                        staticStyle: { width: "calc(100% - 80px)" },
                        slot: "menuLeft"
                      },
                      [
                        _c(
                          "el-row",
                          {
                            staticStyle: {
                              "margin-top": "20px",
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("el-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "chartsHeaderDiv",
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        width: "100%",
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "titImg",
                                        attrs: {
                                          src: "/img/button/angleMark.png",
                                          alt: ""
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "tit",
                                          staticStyle: {
                                            "text-overflow": "ellipsis",
                                            overflow: "hidden",
                                            "white-space": "nowrap",
                                            display: "inline-block",
                                            "vertical-align": "middle"
                                          },
                                          attrs: {
                                            title: _vm.$t(
                                              "SUPPLIER CERTIFICATION QUANTITY"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("PurchaseDetailsList")
                                            )
                                          )
                                        ]
                                      ),
                                      _c("el-button", {
                                        staticClass: "btnDetail",
                                        attrs: {
                                          icon: "el-icon-s-order",
                                          type: "text",
                                          title: _vm.$t("Check the details")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.jupmSupplierCer()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "250px" },
                                        attrs: {
                                          "prefix-icon": "el-icon-search",
                                          placeholder: _vm.$t("inputSearchCon")
                                        },
                                        on: { blur: _vm.quickSearch },
                                        model: {
                                          value: _vm.inputSearch,
                                          callback: function($$v) {
                                            _vm.inputSearch = $$v
                                          },
                                          expression: "inputSearch"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "big" },
                                    on: { click: _vm.exportAll }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Export")))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }